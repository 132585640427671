import $ from 'jquery';

import '../../scss/video.scss';

(function ($, window) {
    $(function () {
        if (window.hideYTActivated) return;

        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        let onYouTubeIframeAPIReadyCallbacks = [];
        for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {

            let playerFrame = playerWrap.querySelector("iframe");

            let onPlayerStateChange = function (event) {
                if (event.data == YT.PlayerState.ENDED) {
                    playerWrap.classList.add("ended");
                } else if (event.data == YT.PlayerState.PAUSED) {
                    playerWrap.classList.add("paused");
                } else if (event.data == YT.PlayerState.PLAYING) {
                    setTimeout(() => {
                        playerWrap.classList.remove("ended");
                        playerWrap.classList.remove("paused");
                    }, 100);
                }
            };

            let onPlayerReady = function(event) {
                playerWrap.classList.add("paused");
            };

            let onPlayerError = function(event) {
                playerWrap.classList.add("paused");
            };
 
            let player; 
            
            onYouTubeIframeAPIReadyCallbacks.push(function () {
                player = new YT.Player(playerFrame, {
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange,
                        'onError': onPlayerError
                    }
                });
            }); 
            
            playerWrap.addEventListener("click", function () {
                let playerState = player.getPlayerState();
                if (playerState == YT.PlayerState.ENDED) { 
                    player.seekTo(0); 
                }
                else if (playerState != YT.PlayerState.PLAYING) {
                    player.playVideo(); 
                }
            });
        }

        window.onYouTubeIframeAPIReady = function () {
            for (let callback of onYouTubeIframeAPIReadyCallbacks) {
                callback();
            }
        };

        window.hideYTActivated = true;

    });
})($, window);