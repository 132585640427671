
import $ from 'jquery';

(function(app, $) {

    const SharedController =  function ($scope, $http, $timeout) {
        
        $scope.lead = {};

        $scope.share = function(e) {
            e.preventDefault();
            console.log('share called', this);
            FB.ui({
                method: 'share',
                hashtag: '#alto-areiao',
                quote: '',
                href: 'https://cmoconstrutora.com.br/altoareiao/'
              }, function(response){});
        };

        $scope.working = false;

        $scope.formSubmit = function(e) {
            e.preventDefault();

            if($scope.working) return;
            $scope.working = true;

            $.post('/altoareiao/backend/forms.php', $scope.lead).then(function (result) {
                $scope.lead = {};
                $scope.working = false;
                setTimeout(function() {
                    window.location = "agradecimento.html";
                }, 100)
            }, function(err) {
                alert('Houve um erro ao cadastrar seu email. Tente novamente em instantes.');
                $scope.working = false;
            });
        };
    };

    app.controller('SharedController', ['$scope', '$http', '$timeout', SharedController]);
})(angular.module('App'), $);