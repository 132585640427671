import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap';
import 'angular';

import '../scss/app.scss';
import lightbox from 'lightbox2';

lightbox.option({
    albumLabel: 'Foto %1 of %2'
});

let app = angular.module('App', [require('angular-input-masks')])
    .config([ '$interpolateProvider', ($interpolateProvider) => {
        $interpolateProvider.startSymbol('[[');
        $interpolateProvider.endSymbol(']]');
    }]).run([function () {
        console.log('angular running');
    }]);

    require('./components/scroll');
    require('./components/loading');
    require('./components/video');
    require('./components/sliders');
require('./controllers/shared.controller');