import $ from "jquery";

import ScrollPosStyler from "scrollpos-styler";

(function($) {

    $(function () {

        function updateScrollOffsets() {
            
        }

        setTimeout(function () {
            updateScrollOffsets();
            ScrollPosStyler.init();
        }, 500);

        $(window).on('resize', function () {
            updateScrollOffsets();
        });

        $('a[data-toggle="scroll"]').on('click', function(event) {

            $(this).closest('.navbar-collapse').collapse('hide');

            // On-page links
            if (
                location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
                &&
                location.hostname === this.hostname
            ) {
                // Figure out element to scroll to
                let target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top+1
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        let $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });
    });
})($);