import $ from 'jquery';
import 'slick-carousel';

(function($) {
    $(function () {
        $('.default-slider').each(function (e) {
            let $el = $(this);
            $el.slick({
                lazyLoad: 'ondemand',
                slidesToShow: 2,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: { slidesToShow: 1, }
                    }
                ],
            });
        });
    });
})($);