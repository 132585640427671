import $ from "jquery";

(function($) {
    $(function () {
        let mainLoading = $('#main-loading');
        setTimeout(() => {
            mainLoading.stop(true,true).fadeOut(200, () => {
                $('body').addClass('loaded');
            });
        }, 1000);
    });
})($);  